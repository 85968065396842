import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import LoginComponent from '../login/login';
import './signup.css';
import { sendVerificationEmail, signup } from '../../../shared/apis';
import signupicon from '../../../assets/images/login-ico.png';
import personicon from '../../../assets/images/person-icon.png';
import googleicon from '../../../assets/images/google-icon.png';
import 'react-phone-input-2/lib/style.css'; // Import the phone input styles
import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component


const SignupComponent = ({ open, onCloseModal }) => {
  const [openLoginComponent, setOpenLoginComponent] = useState(false);
  const [email, setEmail] = useState('');
  const [signupMode, setSignupMode] = useState('email');
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const [fpUser, setFpUser] = useState(null);

  const handleLoginClick = () => {
    setOpenLoginComponent(true);
    onCloseModal();
  };

  const sendCode = async () => {
    if (!email) {
      setError('Please enter an email.');
      setTimeout(() => {
        setError('');
      }, 5000);
    } else if (!validateEmail(email)) {
      setError('Invalid email.');
      setTimeout(() => {
        setError('');
      }, 5000);
    } else {
      try {
        setError('');
        setSignupMode('code');
        const verificationCode = Math.floor(100000 + Math.random() * 900000);
        localStorage.setItem("verificationCode", JSON.stringify(verificationCode));
        let body = {
          code: verificationCode,
          email: email
        };
        let results = await sendVerificationEmail(body);
      } catch (error) {
        if (error.response && error.response.status === 503) {
          setError('An error occurred. Please try again.');
        } else {
          setError('Email already exists.');
          setSignupMode("email")
        }
        setTimeout(() => {
          setError('');
        }, 5000);
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const verifyCode = async () => {
    const code = localStorage.getItem("verificationCode");
    if (verificationCode !== '') {
      if (verificationCode === code) {
        setError('');
        setSignupMode('signup');
        localStorage.removeItem('verificationCode');
      } else {
        setError('Verification code mismatch.');
        setTimeout(() => {
          setError('');
        }, 5000);
      }
    } else {
      setError('No Verification Code Entered.');
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return passwordRegex.test(password);
  };

  const signupNow = async () => {
    // console.log(phoneNumber.length)
    // if (phoneNumber.length > 13 || phoneNumber.length < 12) {
    //   setError('Phone Number Format must be (+1)');
    //   setTimeout(() => {
    //     setError('');
    //   }, 5000);
    //   return;
    // }

    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long and contain at least one capital letter, one number, and one special character (!@#$%^&*).');
      setTimeout(() => {
        setError('');
      }, 5000);
      return;
    }

    let body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: 'USER',
      isEmailVerified: true,
      password: password,
      phoneNumber: phoneNumber,
      deviceType: 'Android',
    };

    if (validateSignUp(body)) {
      setError('');
      let user = await signup(body);
      setUser(user.data.user);
      setFpUser(user.data.FpUser);
      localStorage.setItem("token", JSON.stringify(user.data.token));
      localStorage.setItem("FpToken", JSON.stringify(user.data.FpUser.token));
      onCloseModal();
      navigate('/restaurants');
      window.location.reload();
    } else {
      setError('All fields are mandatory.');
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  const validateSignUp = (body) => {
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        const value = body[key];
        if (value === undefined || value === null || value === '') {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <>
      <div className="signup-modal">
        <Modal open={open} onClose={onCloseModal} classNames={{ modal: 'mymodal' }} center styles={{
          modal: {
            width: '80%',
            maxWidth: '400px',
            height: 'auto',
          }
        }}>
          {signupMode === 'email' &&
            <>
              <div className="signup-popup">
                <img src={signupicon} className='signup-icon' alt="Signup" />
                <br />
                <label htmlFor="Email" className='field-labels'>Email</label>
                <input type="email" className='signup-fields' placeholder='Enter your email' onChange={(e) => { setEmail(e.target.value) }} required />
                <button className='login-btn-modal dialog-btn' onClick={() => sendCode()}>Send Code</button>
                <br />
                <div className='d-flex signup-btn-sec'>
                  <span className='signup-text'>
                    Don't have an account ?
                  </span>
                  <button className='signup-btn-modal signup-btn2 dialog-btn' onMouseDown={handleLoginClick}>Sign In</button>
                </div>
                {error && <p className="error-message">{error}</p>}
                {/* <button className='signup-btn-modal dialog-btn' onMouseDown={handleLoginClick}>Have an account? Sign In</button>
                {error && <div className='error-message'>{error}</div>} */}
              </div>
            </>
          }
          {signupMode === 'code' &&
            <>
              <div className="signup-popup">
                <img src={signupicon} className='signup-icon' alt="Signup" />
                <br />
                <label htmlFor="Code" className='field-labels'>Enter Code</label>
                <img className='login-icons4' src={personicon} alt="Person Icon" />
                <input type="text" className='signup-fields' onChange={(e) => { setVerificationCode(e.target.value) }} required />
                <button className='login-btn-modal dialog-btn' onClick={() => verifyCode()}>Verify</button>
                <br />
                {error && <div className='error-label error-text-red'>{error}</div>}
              </div>
            </>
          }
          {signupMode === 'signup' &&
            <>
              <div className="signup-popup">
                <img src={signupicon} className='signup-icon' alt="Signup" />
                <br />
                <label htmlFor="Code" className='field-labels'>First Name</label>
                <input type="text" className='signup-fields signup-fields-verified' onChange={(e) => { setFirstName(e.target.value) }} required />
                <label htmlFor="Code" className='field-labels'>Last Name</label>
                <input type="text" className='signup-fields signup-fields-verified' onChange={(e) => { setLastName(e.target.value) }} required />
                <label htmlFor="phoneNumber" className="field-labelss">Phone Number</label>
                <PhoneInput
                  country={'us'} // Default country code and flag (optional)
                  value={phoneNumber}
                  // onChange={(phone) => setPhoneNumber(phone)}
                  onChange={(phone) => setPhoneNumber(`+${phone}`)}

                  inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true,
                  }}
                  containerClass="signup-fieldss"
                  inputClass="signup-fields-verifieds"
                  dropdownClass="country-dropdown"
                  enableSearch // Adds a search field in the dropdown for countries
                />

                {/* <label htmlFor="Code" className='field-labels'>Phone Number</label>
                <input type="text" className='signup-fields signup-fields-verified' onChange={(e) => { setPhoneNumber(e.target.value) }} required /> */}
                <label htmlFor="Code" className='field-labels'>Password</label>
                <input type="password" className='signup-fields signup-fields-verified' onChange={(e) => { setPassword(e.target.value) }} required />
                <button className='login-btn-modal dialog-btn' onClick={() => signupNow()}>Sign up</button>
                <br />
                {error && <div className='error-label error-text-red'>{error}</div>}
              </div>
            </>
          }
        </Modal>
      </div>
      {openLoginComponent && (
        <LoginComponent open={true} onCloseModal={() => setOpenLoginComponent(false)} />
      )}
    </>
  );
};

export default SignupComponent;
