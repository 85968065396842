// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import HomePage from "./componentes/home/homepage";
// import Footer from "./componentes/Footer/footer";
// import '@fortawesome/fontawesome-free/css/all.css';
// import Facebook from "./componentes/tracking/Facebook";
// import LandingGif from "./componentes/landing-gif/landing-gif";

// export const ConvertPKRtoUSD = (price) => {
//   const userCountry = localStorage.getItem("country");

//   if (userCountry === 'US') {
//     return `$${price}`;

//   }
//   else if (userCountry === 'CA') {
//     return `Rs ${Math.round(price)}`;
//   }
//   else if (userCountry === 'GB') {
//     return `Rs ${Math.round(price)}`;
//   }
//   else if (userCountry === 'AU') {
//     return `Rs ${Math.round(price)}`;
//   }
//   else if (userCountry === 'AE') {
//     return `Rs ${Math.round(price)}`;
//   }
//   else if (userCountry === 'SA') {
//     return `Rs ${Math.round(price)}`;
//   }
//   else {
//     return `Rs ${Math.round(price)}`;
//   }
// };

// function App() {
//   const [location, setLocation] = useState(localStorage.getItem('country'));
//   const [error, setError] = useState(null);
//   const [convertedPrice, setConvertedPrice] = useState(null);

//   useEffect(() => {
//     const fetchLocation = async () => {
//       try {
//         const response = await axios.get(`https://ipinfo.io/json?token=6e6f5105a4e867`);
//         const country = response.data.country;
//         const storedCountry = localStorage.getItem('country');
//         if (storedCountry !== country) {
//           localStorage.setItem('country', country);
//           setLocation(country);
//         }
//       } catch (error) {
//         setError('Could not determine location.');
//       }
//     };
//     fetchLocation();
//   }, []);

//   useEffect(() => {
//     const convertPrice = () => {
//       const price = 1000;
//       const result = ConvertPKRtoUSD(price);
//       setConvertedPrice(result);
//     };

//     if (location) {
//       convertPrice();
//     }
//   }, [location]);

//   if (error) {
//     return <div>{error}</div>;
//   }

//   if (location === null) {
//     return null;
//   }

//   if (location === 'US' || location === 'PK' || location === 'CA' || location === 'GB' || location === 'AU' || location === 'AE' || location === 'SA') {
//     return (
//       <div>
//         <Facebook />
//         <LandingGif />
//         <HomePage />
//         <Footer />
//       </div>
//     );
//   } else {
//     return (
//       <div style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '100vh',
//         textAlign: 'center',
//       }}>
//         <div>
//           <p style={{
//             padding: '20px',
//             borderRadius: '10px',
//             color: '#333',
//             fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
//             fontSize: '18px',
//             color: '#d4110f',
//             fontSize: 'calc(10px + 1vw)',
//             fontWeight: '500'
//           }}>
//             Sorry...!
//             <br />
//             We are not offering our services in your country.
//           </p>
//         </div>
//       </div>
//     );
//   }
// }

// export default App;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import HomePage from "./componentes/home/homepage";
import Footer from "./componentes/Footer/footer";
import '@fortawesome/fontawesome-free/css/all.css';
import Facebook from "./componentes/tracking/Facebook";
import LandingGif from "./componentes/landing-gif/landing-gif";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export const ConvertPKRtoUSD = (price) => {
  const userCountry = localStorage.getItem("country");

  if (userCountry === 'US'||userCountry === 'CA') {
    return `$${price}`;
  } else {
    return `Rs ${Math.round(price)}`;
  }
};

function App() {
  const [location, setLocation] = useState(localStorage.getItem('country'));
  const [error, setError] = useState(null);
  const [convertedPrice, setConvertedPrice] = useState(null);

  const fetchLocation = async () => {
    try {
      const response = await axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`);
      const country = response.data.country;
      const storedCountry = localStorage.getItem('country');
      if (storedCountry !== country) {
        localStorage.setItem('country', country);
        setLocation(country);
      }
    } catch (error) {
      setError('Could not determine location.');
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    const convertPrice = () => {
      const price = 1000;
      const result = ConvertPKRtoUSD(price);
      setConvertedPrice(result);
    };

    if (location) {
      convertPrice();
    }
  }, [location]);

  if (error) {
    return <div>{error}</div>;
  }

  if (location === null) {
    return null;
  }

  if (location === 'PK') {
    return (
      <div>
        <Facebook />
        <LandingGif />
          <HomePage />
        <Footer />
      </div>
    );
  } else {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}>
        <div>
          <p style={{
            padding: '20px',
            borderRadius: '10px',
            color: '#333',
            fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
            fontSize: '18px',
            color: '#d4110f',
            fontSize: 'calc(10px + 1vw)',
            fontWeight: '500'
          }}>
            Sorry...!
            <br />
            We are not offering our services in your country.
          </p>
        </div>
      </div>
    );
  }
}

export default App;
